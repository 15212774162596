body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo>svg {
  height: 60px;
}
.alert_column{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.alert_column .ant-btn{
    background-color: #fa6400;
    border-color: #fa6400;
}
.alert_column b{
    font-weight: 700;
    color: #9e0d0d;
}
.logo {
  height: 100%;
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.header {
  height: 100px !important;
  background-color: #fff !important;
  box-shadow: 0 8px 16px rgba(110, 120, 130, 0.12);
  margin: 10px !important;
  padding: 5px 15px !important;
  border-radius: 10px;
}
.ant-layout-has-sider {
  display: flex;
  flex-direction: row;
  background-color: #fff !important;
  margin: 10px !important;
  padding: 5px 20px !important;
  border-radius: 10px;
}
.ant-layout-sider {
  background-color: unset!important;
}



.ant-menu-inline>.ant-menu-item:nth-child(1) {
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.ant-switch-inner {
  display: flex !important;
}
.header_info{

}

.ant-switch{
  width: 55px!important;
}
.ant-row{
  height: 100%;
}
.admin_block_user,.admin_block_balance,.admin_block_action,.admin_block_admin{
  padding: 0px 15px;
  display: flex!important;
  flex-direction: column!important;
  align-items: center!important;
  align-content: center!important;
}
.admin_block_user{
  height: 100%;
  justify-content: space-around!important;
}
.admin_block_balance{
  justify-content: space-evenly!important;
  align-self: center!important;
}
.admin_block_action{
  justify-content: space-evenly!important;
}
.admin_block_admin{
  justify-content: space-evenly!important;
}
.user_name_tag{
  margin: 0!important;
  font-weight: 900;
}
.recovery_alert > .anticon{
    color: #6483ff!important;
    margin-right: 10px;
}
.password_reset_link{
    font-weight: 700!important;
}
.recovery_alert{
    margin-top: 0!important;
    margin-bottom: 26px!important;
    font-weight: 600!important;
    background-color: #dae5ff!important;
    border: 2px solid #6483ff!important;
}
.alert_error{
    margin-top: 6px!important;
    margin-bottom: 6px!important;
}
.danone{
    display: none!important;
}
.modal_window{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fffffff0;
    z-index: 666;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
